import React from 'react';

const PartnerCardList = ({partnerData, handleRowClick}) => {
    // 숫자로 변환하고 유효한 값만 사용하는 함수
    const safeNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);

    const formatKrw = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(1).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };

    // 모든 파트너의 합계 계산
    const totalTradingAccount = partnerData.reduce((sum, partner) => sum + safeNumber(partner.partnerData?.binance_data?.free), 0);
    const totalFundingAccount = partnerData.reduce((sum, partner) => sum + safeNumber(partner.partnerData?.binance_data?.funding_free), 0);
    const totalAvailableAmount = Math.floor(totalTradingAccount) + Math.floor(totalFundingAccount);
    const lastPrice = safeNumber(partnerData[0]?.partnerData?.binance_data?.last_price);
    const totalKrwCalculation = totalAvailableAmount * lastPrice;

    return (
        <div className="partner-card-list">
            {/* 합계 카드 */}
            <div className="partner-card total-card">
                <h3>전체 합계</h3>
                <p>총 트레이딩 계좌: {Math.floor(totalTradingAccount).toLocaleString()} USDT</p>
                <p>총 펀딩 계좌: {Math.floor(totalFundingAccount).toLocaleString()} USDT</p>
                <p>시세: {formatKrw(lastPrice)} KRW</p>
                <p>총 사용가능수량: {Math.floor(totalAvailableAmount).toLocaleString()} USDT</p>
                <p>총 원화계산: {Math.floor(totalKrwCalculation).toLocaleString()} KRW</p>
            </div>

            {/* 기존 파트너 카드 */}
            {partnerData.map((partner) => {
                const tradingAccount = safeNumber(partner.partnerData?.binance_data?.free);
                const fundingAccount = safeNumber(partner.partnerData?.binance_data?.funding_free);
                const lastPrice = Math.floor(partner.partnerData?.binance_data?.last_price);
                const availableAmount = Math.floor(tradingAccount) + Math.floor(fundingAccount);
                const krwCalculation = availableAmount * lastPrice;
                return (
                    <div key={partner.id} className="partner-card" onClick={() => handleRowClick(partner)}>
                        <h3>{partner.username}</h3>
                        <p>트레이딩 계좌: {Math.floor(tradingAccount).toLocaleString()} USDT</p>
                        <p>펀딩 계좌: {Math.floor(fundingAccount).toLocaleString()} USDT</p>
                        <p>시세: {formatKrw(lastPrice)} KRW</p>
                        <p>사용가능수량: {Math.floor(availableAmount).toLocaleString()} USDT</p>
                        <p>원화계산: {Math.floor(krwCalculation).toLocaleString()} KRW</p>
                    </div>
                )
            })}
        </div>
    );
};

export default PartnerCardList;
