import React from 'react';
import '../../static/Step.css';
import pasteIcon from '../../assets/icon-clipboard-paste.png'; // 붙여넣기 아이콘 이미지 경로 설정
import useClipboard from '../customhook/useClipboard';

const Step3 = ({ formData, handleChange, handleNext, handlePrevious }) => {
    const { handlePaste } = useClipboard();

    const isStep3Valid = () => {
        return formData.bank && formData.accountNumber && formData.accountHolder;
    };

    const previous = () => handlePrevious()
    const next = () => handleNext()

    return (
        <>
            <h2 className="page-title">은행 계좌</h2>
            <div className="notice">
                모든 필수 항목을 정확히 입력해 주세요.
            </div>
            <div className="inputs">
                <div className="input with-icon">
                    <input
                        type="text"
                        placeholder="은행명"
                        name="bank"
                        value={formData.bank}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <img src={pasteIcon} alt="Paste" className="paste-icon"
                         onClick={() => handlePaste('accountNumber', handleChange)}/>
                    <input
                        type="text"
                        placeholder="계좌번호"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="input with-icon">
                    <input
                        type="text"
                        placeholder="예금주"
                        name="accountHolder"
                        value={formData.accountHolder}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="navigation-buttons">
                <button onClick={previous}>이전으로</button>
                <button onClick={next} disabled={!isStep3Valid()}>
                    다음
                </button>
            </div>
        </>
    );
};

export default Step3;
