import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApiRequest from '../components/fetch/useApiRequest'; // API 요청 훅
import '../static/PartnerDetail.css';
import { FaArrowLeft } from 'react-icons/fa';
import OrderList from "../components/OrderList";
import Loading from "../components/Loading";

const PartnerDetail = () => {
    const location = useLocation();
    const navigate = useNavigate(); // 이전 화면으로 돌아가기 위해 useNavigate 훅 사용
    // 이 코드는 React Router의 location 객체에서 partner 정보를 추출합니다.
    // location.state에 partner 정보가 있으면 그것을 사용하고, 없으면 빈 객체를 사용합니다.
    // 이렇게 하면 페이지 새로고침 시에도 partner 정보가 유지됩니다.
    const { partner } = location.state || {};
    const [orderAmount, setOrderAmount] = useState(''); // 주문 금액 상태
    const [transferAmount, setTransferAmount] = useState('');
    const [transferType, setTransferType] = useState('MAIN_FUNDING');
    const { postApiRequest, loading } = useApiRequest();
    const token = localStorage.getItem('access_token');

    if (!partner) {
        return <div className="error-message">파트너 정보를 불러올 수 없습니다.</div>;
    }

        // USDT 금액 포맷팅 함수
    const formatKrw = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(1).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };


    const handleOrderRequest = async () => {
        if (!orderAmount) {
            alert('주문 금액을 입력해주세요.');
            return;
        }

        // 확인 대화상자 추가
        const isConfirmed = window.confirm(`${orderAmount} USDT를 주문하시겠습니까?`);
        if (!isConfirmed) {
            return; // 사용자가 취소를 선택한 경우
        }

        try {
            await postApiRequest(
                '/admin/partner/order', // 주문 API 엔드포인트
                {
                    amount: orderAmount,
                    price: koreanWonLastprice,
                    user_id: partner.id

                }, // 요청 본문에 금액 포함
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                (data) => {
                    alert(data.message);
                    setOrderAmount('')
                },
                (error) => {
                    alert(error);
                }
            );

        } catch (error) {
            console.error('주문 요청 중 오류가 발생했습니다:', error);
        }
    };

    const handleTransferRequest = async () => {
        if (!transferAmount) {
            alert('트랜스퍼 금액을 입력해주세요.');
            return;
        }

        const isConfirmed = window.confirm(`${transferAmount} USDT를 ${transferType === 'MAIN_FUNDING' ? '메인에서 펀딩으로' : '펀딩에서 메인으로'} 트랜스퍼 하시겠습니까?`);
        if (!isConfirmed) {
            return;
        }

        try {
            await postApiRequest(
                '/admin/partner/transfer',
                {
                    amount: transferAmount,
                    type: transferType
                },
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                (data) => {
                    alert(data.message);
                    setTransferAmount('');
                },
                (error) => {
                    alert(error);
                }
            );
        } catch (error) {
            console.error('트랜스퍼 요청 중 오류가 발생했습니다:', error);
        }
    };

    const handleGoBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };

    // 구매 가능수량 계산
    const availablePurchaseAmount = (
        Math.floor(partner.partnerData?.binance_data?.free || 0) +
        Math.floor(partner.partnerData?.binance_data?.funding_free || 0)
    );

    // 원화 계산
    const koreanWonLastprice = partner.partnerData?.binance_data?.last_price || 0;
    const koreanWonAmount = Math.floor(availablePurchaseAmount * koreanWonLastprice || 0);

    return (
        <>
            <div className="partner-detail-container">
                <div className="partner-detail-header">
                    <FaArrowLeft onClick={handleGoBack} className="back-icon" /> {/* 이전 화면으로 돌아가기 아이콘 */}
                    <h1 className="partner-detail-title">Partner Information</h1>
                </div>
                <div className="partner-name-header">
                    <h2 className="partner-name">{partner.username}</h2>
                </div>
                <div className="partner-detail-card">
                    <div className="partner-info">
                        <div className="partner-info-row">
                            <span>이메일:</span> <span>{partner.email}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>추천코드:</span> <span>{partner.referral_code}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>전화번호:</span> <span>{partner.partner?.phone_number}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>은행:</span> <span>{partner.partner?.bank_name}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>계좌번호:</span> <span>{partner.partner?.bank_account_number}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>예금주:</span> <span>{partner.partner?.account_holder_name}</span>
                        </div>
                    </div>
                </div>

                <h2 className="partner-detail-subtitle">바이낸스 정보</h2>
                <div className="partner-detail-card">
                    <div className="partner-info">
                        <div className="partner-info-row">
                            <span>트레이딩 계좌:</span>
                            <span>{Math.floor(partner.partnerData?.binance_data?.free || 0).toLocaleString()} USDT</span>
                        </div>
                        <div className="partner-info-row">
                            <span>펀딩 계좌:</span>
                            <span>{Math.floor(partner.partnerData?.binance_data?.funding_free || 0).toLocaleString()} USDT</span>
                        </div>
                        <div className="partner-info-row">
                            <span>구매가능수량:</span>
                            <span>{availablePurchaseAmount.toLocaleString()} USDT</span>
                        </div>
                        <div className="partner-info-row">
                            <span>현재시세:</span>
                            <span>{formatKrw(koreanWonLastprice)} 원</span>
                        </div>
                        <div className="partner-info-row">
                            <span>원화계산:</span>
                            <span>{koreanWonAmount.toLocaleString()} 원</span>
                        </div>
                    </div>
                </div>

                <h2 className="partner-detail-subtitle">주문</h2>

                <div className="partner-detail-form">
                    <input
                        type="number"
                        value={orderAmount}
                        onChange={(e) => setOrderAmount(e.target.value)}
                        placeholder="주문수량"
                    />
                    <button onClick={handleOrderRequest}>주문요청</button>
                </div>

                <h2 className="partner-detail-subtitle">트랜스퍼</h2>
                <div className="partner-detail-form">
                    <input
                        type="number"
                        value={transferAmount}
                        onChange={(e) => setTransferAmount(e.target.value)}
                        placeholder="트랜스퍼 수량"
                    />
                    <select
                        value={transferType}
                        onChange={(e) => setTransferType(e.target.value)}
                    >
                        <option value="MAIN_FUNDING">메인 → 펀딩</option>
                        <option value="FUNDING_MAIN">펀딩 → 메인</option>
                    </select>
                    <button onClick={handleTransferRequest}>트랜스퍼 요청</button>
                </div>

                <h2 className="partner-detail-subtitle">주문목록</h2>
                {/* 주문 목록 표시 */}
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <OrderList token={token} endpoint={`/admin/partner/detail/${partner.id}/orders`} userBool={false} />
                </div>
                {/* 로딩표시 */}
                {loading ? <Loading isFullScreen={true}/> : null}
            </div>
        </>
    );
};

export default PartnerDetail;
