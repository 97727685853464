const useClipboard = () => {

    const handlePaste = async (fieldName, handleChange) => {
        try {
            const text = await navigator.clipboard.readText();
            handleChange({ target: { name: fieldName, value: text } });
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };

    const handleCopy = async (info) => {
        try {
            await navigator.clipboard.writeText(info);
            alert(`${info} 가 클립보드에 복사되었습니다.`);
        } catch (err) {
            console.error('Failed to copy address: ', err);
        }
    };

    return { handlePaste, handleCopy };
};

export default useClipboard;
