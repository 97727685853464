import React from 'react';
import { Outlet } from 'react-router-dom';

const AdminPage = () => {
    return (
        <div>
            <Outlet /> {/* 중첩된 경로 렌더링 */}
        </div>
    );
};

export default AdminPage;
