import OrderList from '../components/OrderList';
import '../static/UserDetail.css';
import useUserData from '../components/customhook/useUserData'
import { useCallback, useEffect, useState, useMemo } from 'react';
import Loading from "../components/Loading";

const UserDetails = () => {
  const token = localStorage.getItem('access_token');
  const [user, setUserdata] = useState()
  const [loading, setLoading] = useState(true);
  const { getuserdata } = useUserData();

  const copyToClipboard = async (text) => {
    try {
      const referral_link = `https://partners-ex.bonegowallet.com/partner-signup?referral=${text}`
      await navigator.clipboard.writeText(referral_link);
      alert('복사됨!');
    } catch (err) {
      alert('복사 실패');
    }
  };

  const memoizedUser = useMemo(() => user, [user]);

  const getUser = useCallback(async () => {
    const response = await getuserdata(token);
    if (response) {
      // 새로운 데이터가 이전 데이터와 다른 경우에만 상태 업데이트
      if (JSON.stringify(response.data) !== JSON.stringify(memoizedUser)) {
        setUserdata(response.data);
      }
      setLoading(false);
    }
  }, [token, getuserdata, memoizedUser]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);


  if (!user) return <Loading isFullScreen={true}/>
  if (loading) return <Loading isFullScreen={true}/>

  return (
    <>
      <div className="partner-detail-container">
        <div className="partner-detail-header">
          <h1 className="partner-detail-title">User Information</h1>
        </div>
        <div className="partner-name-header">
          <h1 className="partner-name">{user.username}</h1>
        </div>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row">
              <span>이메일:</span> <span>{user.email}</span>
            </div>
            <div className="partner-info-row" onClick={() => copyToClipboard(user.referral_code)}
                 style={{cursor: 'pointer'}}>
              <span>추천코드(클릭시 복사):</span> <span>{user.referral_code}</span>
            </div>
            <div className="partner-info-row">
              <span>전화번호:</span> <span>{user.partner?.phone_number}</span>
            </div>
            <div className="partner-info-row">
              <span>은행:</span> <span>{user.partner?.bank_name}</span>
            </div>
            <div className="partner-info-row">
              <span>계좌번호:</span> <span>{user.partner?.bank_account_number}</span>
            </div>
            <div className="partner-info-row">
              <span>예금주:</span> <span>{user.partner?.account_holder_name}</span>
            </div>
          </div>
        </div>
        <h2 className="partner-detail-subtitle">바이낸스 정보</h2>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row full-width">
              <span>API 키:</span>
              <span>{user.partner?.binance_api_key?.length > 20 ? `${user.partner.binance_api_key.slice(0, 20)}...` : user.partner?.binance_api_key }</span>
            </div>
            <div className="partner-info-row full-width">
              <span>USDT 입금주소:</span>
              <span>{user.partner?.binance_usdt_trc20_address}</span>
            </div>
          </div>
        </div>
        <h2 className="partner-detail-subtitle">주문목록</h2>
        {/* 주문 목록 표시 */}
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <OrderList token={token} endpoint={`/orders`} userBool={false} />
        </div>
      </div>
    </>
  );
};

export default UserDetails;