import React from 'react';
import formatDate from './FormatDate';

const OrderTable = ({orders, onDetailView, user}) => {
    const formatKrw = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(1).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };
    return (
        <table className="order-list-table">
            <thead>
            <tr>
                <th>주문 날짜</th>
                {user ? <th>사용자</th> : null}
                <th>주문 수량</th>
                <th>주문 가격</th>
                <th>상태</th>
                <th>상세보기</th>
            </tr>
            </thead>
            <tbody>
            {orders.length > 0 ? (
                orders.map(order => (
                    <tr key={order.id}>
                        <td>{formatDate(order.created_at)}</td>
                        {user ? <td>{order.user?.username}</td> : null}
                        <td>{order.amount.toLocaleString()} USDT</td>
                        <td>{formatKrw(order.price)} 원</td>
                        <td>{order.status}</td>
                        <td>
                            <button className="detail-button" onClick={() => onDetailView(order.id)}>
                                상세보기
                            </button>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="5">주문 내역이 없습니다.</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default OrderTable;
