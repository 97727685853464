import { useState } from 'react';
import axios from 'axios';

const useApiRequest = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const getApiRequest = async (url, params, headers, onSuccess, onError) => {
        setLoading(true)
        setError(null);
        try {
            const response = await axios.get(BASE_URL + url, {
                headers
            });
            if (response.status === 200) {
                onSuccess(response.data);  // 요청 성공 시 데이터를 콜백 함수로 전달
            } else {
                const errorMessage = response?.data?.error_msg
                    || response?.data?.detail
                    || '';
                const message = 'API 요청에 실패했습니다 : '
                setError(message + errorMessage);
                if (onError) onError(message + errorMessage);  // 에러 콜백 호출
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error_msg
                || err.response?.data?.detail
                || '';
            const message = 'API 요청에 실패했습니다 : '
            setError(message + errorMessage);
            if (onError) onError(message + errorMessage);  // 에러 콜백 호출
        } finally {
            setLoading(false)
        }
    };

    const postApiRequest = async (url, params, headers, onSuccess, onError) => {
        setLoading(true)
        setError(null);

        try {
            const response = await axios.post(BASE_URL + url, params, {
                headers
            });

            if (response.status === 200) {
                onSuccess(response.data);  // 요청 성공 시 데이터를 콜백 함수로 전달
            } else {
                const errorMessage = response?.data?.error_msg
                    || response?.data?.detail
                    || '';
                const message = 'API 요청에 실패했습니다 : '
                setError(message + errorMessage);
                if (onError) onError(message + errorMessage);  // 에러 콜백 호출
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error_msg
                || err.response?.data?.detail
                || '';
            const message = 'API 요청에 실패했습니다 : '
            setError(message + errorMessage);
            if (onError) onError(message + errorMessage);  // 에러 콜백 호출
        } finally {
            setLoading(false)
            // setLoading(false);
        }
    };

    return { getApiRequest, postApiRequest, loading, error };
};

export default useApiRequest;
