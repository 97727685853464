import { useState } from 'react';
import React from 'react';
import '../../static/TermsOfService.css'

const TermsOfService = ({ handleNext }) => {
    const [isChecked, setIsChecked] = useState(false); // 체크박스 상태 추가

    const onChange = () => {
        setIsChecked(!isChecked); // 체크박스 상태 토글
    };

    const isStep0Valid = () => {
        return isChecked && true;
    };

    return (
        <div className='content'>
        <div className="terms-of-service">
            <h2>Bonego Partners 회원가입 약관</h2>
            <p>본 약관은 Bonego Wallet에 테더(USDT)를 공급하는 Bonego Partners 시스템의 회원으로 가입하고자 하는 모든 파트너가 반드시 숙지하고 동의해야 하는 사항을 담고 있습니다. 이용자는 본 약관에 동의함으로써 아래 모든 조건에 동의한 것으로 간주됩니다.</p>
            <br />
            <h3>1. 파트너스의 책임 및 면책</h3>
            <ol>
                <li>
                    <strong>Bonego Wallet 지급 관련 면책</strong>
                    <ul>
                        <li>Bonego Wallet이 테더 공급 대가로 지급하는 자금에 대한 모든 법적 책임은 Bonego Wallet에 있으며, 지급 과정에서 발생할 수 있는 문제에 대해 파트너스는 책임을 지지 않습니다.</li>
                        <li>Bonego Wallet은 파트너스에게 지급되는 수수료를 포함한 자금의 정확한 지급을 보장합니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>테더 공급 방식에 따른 책임 한계</strong>
                    <ul>
                        <li>파트너스는 두 가지 방식으로 테더를 공급할 수 있으며, 회사가 현금과 수수료를 먼저 지급한 후에 테더가 출금됩니다.</li>
                        <li>
                            <ul>
                                <strong>
                                    타입 1: 현금 보유 및 거래소 매수를 통한 테더 공급
                                </strong>
                                <li>Bonego Wallet의 요청 시, 회사는 파트너스 계좌로 현금과 수수료를 송금한 후, 파트너스의 계좌에서 자동으로 테더를 매수하여 출금이 진행됩니다.</li>
                            </ul>
                            <ul>
                                <strong>
                                    타입 2: 테더 보유자 대상 공급 방식
                                </strong>
                                <li><strong>첫 번째 방법 : </strong>Bonego Wallet이 수수료를 포함한 현금을 파트너스 계좌로 먼저 송금한 후, 파트너스가 보유한 테더가 자동으로 Bonego Wallet에 전송됩니다.</li>
                                <li><strong>두 번째 방법 : </strong>파트너스가 현금 대신 테더로 수수료를 받고자 하는 경우, 보유 중인 테더로 먼저 Bonego Wallet의 요청을 충족합니다. 회사는 공급된 테더에 대한 대가를 24시간 내에 수수료를 포함한 테더로 반환합니다.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>2. 자동 출금 시스템에 대한 동의</h3>
            <ol>
                <li>
                    <strong>자동 출금 시스템 동의</strong>
                    <ul>
                        <li>Bonego Wallet은 테더 공급을 위해 파트너스의 거래소 계정에서 자동 출금 시스템을 운영합니다. 파트너스로 가입함으로써, 이용자는 이 자동 출금 시스템에 동의하는 것으로 간주됩니다.</li>
                        <li>Bonego Wallet의 요청에 따라, 회사가 현금을 지급한 후 파트너스 계정에서 자동으로 테더가 출금됩니다. 지정된 지갑 주소로만 출금이 가능하도록 화이트리스트 설정이 이루어집니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>임의 출금 금지 및 법적 책임</strong>
                    <ul>
                        <li>Bonego Wallet은 요청에 따라 지정된 지갑 주소로만 출금을 진행하며, 임의로 파트너스의 자금을 출금하지 않습니다.</li>
                        <li>만약 Bonego Wallet이 요청 이외의 목적으로 파트너스의 자금을 임의로 출금할 경우, 회사는 이에 대한 법적 책임을 지며, 파트너스는 이에 따른 모든 손해 배상을 청구할 권리가 있습니다.</li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>3. 자금 및 개인정보 보호</h3>
            <ol>
                <li>
                    <strong>API 정보 및 보안 설정</strong>
                    <ul>
                        <li>파트너스는 자동 테더 공급을 위해 거래소 API 정보를 제공하며, 안전을 위해 출금 화이트리스트 기능을 설정할 수 있습니다. 이를 통해 Bonego Wallet이 임의로 자금을 인출할 수 없도록 보호받습니다.</li>
                        <li>Bonego Wallet은 파트너스의 자금을 보호하기 위한 보안 절차를 준수하며, 무단 출금이나 접근을 방지하기 위한 추가적인 조치를 제공합니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>개인 정보 및 은행 정보 입력</strong>
                    <ul>
                        <li>파트너스는 수수료 지급을 위해 필요한 은행 정보 및 기타 개인정보를 제공해야 합니다. Bonego Wallet은 제공된 정보를 철저히 보호하며, 무단 유출을 방지하기 위한 보안 조치를 준수합니다.</li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>4. AML/KYC 및 외환관리법 준수 의무</h3>
            <ol>
                <li>
                    <strong>자금세탁방지(AML) 및 고객 확인(KYC) 절차</strong>
                    <ul>
                        <li>파트너스는 자금세탁방지(AML)와 고객 확인(KYC) 규정을 준수해야 하며, Bonego Wallet이 요구하는 경우 자금 및 테더의 출처에 대한 추가 정보를 제공해야 합니다.</li>
                        <li>자산 출처가 불법적이거나 규제에 위배될 경우, Bonego Wallet은 파트너스의 서비스 이용을 제한하거나 관련 당국에 보고할 수 있습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>외환관리법 준수 의무</strong>
                    <ul>
                        <li>본 서비스는 외환 송금 대행 서비스를 제공하지 않으며, 파트너스는 외환 송금 목적으로 본 서비스를 사용할 수 없습니다.</li>
                        <li>외환 송금 목적이나 외환 규제를 우회하기 위해 본 서비스를 악용할 경우, 발생하는 모든 법적 책임은 파트너스 본인에게 있습니다. 회사는 외환 송금으로 의심되는 거래를 발견할 경우 해당 거래를 차단하고 필요 시 관련 당국에 보고할 권리가 있습니다.</li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>5. 서비스 이용의 안정성과 신뢰성</h3>
            <ol>
                <li>
                    <strong>테더 공급 안정성 보장</strong>
                    <ul>
                        <li>파트너스는 Bonego Wallet의 요청에 따라 안정적으로 테더를 공급할 책임이 있으며, 공급 과정에서 발생할 수 있는 네트워크 문제나 기타 외부 요인으로 인한 지연에 대해서는 파트너스가 책임지지 않습니다.</li>
                    </ul>
                </li>
                <li>
                    <strong>책임 이행 및 불법 행위 금지</strong>
                    <ul>
                        <li>파트너스는 불법 행위와 연관된 자산을 Bonego Wallet에 공급할 수 없으며, 이를 위반할 경우 본 약관에 따라 책임을 지게 됩니다.</li>
                    </ul>
                </li>
            </ol>
            <br />
            <h3>본 약관에 대한 동의</h3>
            <p>본인은 Bonego Partners 회원으로서 본 약관에 명시된 모든 사항을 이해하고, 이에 동의하며, 회사에 테더를 공급함에 있어 발생할 수 있는 법적 문제에서 면제됨을 인정받으며, 공급하는 자산의 합법성을 보증합니다.</p>
            <br/>
            <label>
                <input type="checkbox" onChange={onChange} /> 약관에 동의합니다.
            </label>
            <div className="navigation-buttons">
                <button onClick={() => handleNext()} disabled={!isStep0Valid()}>다음</button>
            </div>
        </div>
        </div>
    );
};
export default TermsOfService;