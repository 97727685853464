import React, { createContext, useReducer, useEffect } from 'react';

export const UserContext = createContext();

const initialState = {
    user: null,
    isAuthenticated: false,
    loading: true,
};

function userReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                loading: false,
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                loading: false,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    const logout = () => {
        localStorage.removeItem('access_token');
        dispatch({ type: 'LOGOUT' });
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (!token) {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, []);

    return (
        <UserContext.Provider value={{ ...state, dispatch, logout }}>
            {children}
        </UserContext.Provider>
    );
};
