import { useState } from 'react';
import axios from 'axios';

const useSignup = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const signup = async (username, email, password, referral) => {
        setLoading(true);
        setError(null);
        const BASE_URL = process.env.REACT_APP_API_BASE_URL

        try {
            const response = await axios.post(`${BASE_URL}/register`, {
                username: username,
                email: email,
                password: password,
                referral: referral,
            }, {
                headers: { 'Content-Type': 'application/json' }
            });


            return response;
        } catch (err) {
            console.log(err)
            setError('회원가입 실패');
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { signup, loading, error };
};

export default useSignup;
