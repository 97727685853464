const formatPhoneNumber = (value, step) => {
    const cleaned = ('' + value).replace(/\D/g, ''); // 숫자만 남기기
    let match;

    if (cleaned.length === 4) {
        match = cleaned.match(/^(\d{3})(\d{1})$/);
        if (match) {
            return `${match[1]}-${match[2]}`;
        }
    } else if (cleaned.length === 8) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{1})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
    } else if (cleaned.length === 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
    }

    return value;
};


export default formatPhoneNumber;