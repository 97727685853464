import { useEffect } from 'react';

const WebSocketComponent = ({ userId }) => {
    useEffect(() => {
        let socket;
        let reconnectInterval;
        let socketUrl = process.env.REACT_APP_SOCKET_BASE_URL
        const connectWebSocket = () => {
            socket = new WebSocket(`${socketUrl}/notifications/ws/${userId}`);
            console.log("웹소켓이 연결되었습니다");
            // 메시지 수신 시 호출되는 함수
            socket.onmessage = (event) => {
                const message = event.data;
                alert(message);
            };

            // 연결이 종료되었을 때 호출되는 함수
            socket.onclose = () => {
                console.log('웹소켓 연결이 종료되었습니다. 재연결을 시도합니다.');
                // 재연결 시도
                reconnectInterval = setTimeout(() => {
                    connectWebSocket();
                }, 3000); // 3초 후 재연결 시도
            };

            // 연결 오류 발생 시 호출되는 함수
            socket.onerror = (error) => {
                console.error('웹소켓 오류 발생:', error);
                socket.close(); // 오류 발생 시 연결을 닫고 onclose가 호출됨
            };
        };

        connectWebSocket();

        // 컴포넌트가 언마운트될 때 웹소켓 연결 해제 및 재연결 시도 중단
        return () => {
            if (socket) socket.close();
            if (reconnectInterval) clearTimeout(reconnectInterval);
        };
    }, [userId]);

    return null; // 컴포넌트에서 시각적으로 보여줄 내용이 없으므로 null 반환
};

export default WebSocketComponent;
