import { useState } from 'react';
import axios from 'axios';

const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async (email, password) => {
        setLoading(true);
        setError(null);
        const BASE_URL = process.env.REACT_APP_API_BASE_URL

        try {
            const response = await axios.post(`${BASE_URL}/login`, {
                grant_type: 'password',
                username: email,
                password: password,
            }, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });

            // 로그인 성공 시 토큰 반환
            return response.data.access_token;
        } catch (err) {
            setError('Invalid email or password. Please try again.');
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { login, loading, error };
};

export default useLogin;
