// components/Dashboard.jsx
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div>
            <Outlet /> {/* 중첩된 경로 렌더링 */}
        </div>
    );
};

export default Dashboard;
