import React from 'react';

const PartnerTable = ({partnerData, handleRowClick}) => {
    const totals = partnerData.reduce((acc, partner) => {
        const tradingAccount = Number(partner.partnerData?.binance_data?.free) || 0;
        const fundingAccount = Number(partner.partnerData?.binance_data?.funding_free) || 0;
        const availableAmount = Math.floor(tradingAccount) + Math.floor(fundingAccount);
        const krwCalculation = availableAmount * (Number(partner.partnerData?.binance_data?.last_price) || 0);
        return {
            tradingAccount: acc.tradingAccount + tradingAccount,
            fundingAccount: acc.fundingAccount + fundingAccount,
            availableAmount: acc.availableAmount + availableAmount,
            krwCalculation: acc.krwCalculation + krwCalculation,
        };
    }, {tradingAccount: 0, fundingAccount: 0, availableAmount: 0, krwCalculation: 0});

    const formatKrw = (amount) => {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        let [integerPart, decimalPart] = amount.toFixed(1).split('.');
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${integerPart}.${decimalPart}`;
    };

    return (
        <table className="partner-list-table">
            <thead>
            <tr>
                <th>이름</th>
                <th>트레이딩 계좌</th>
                <th>펀딩 계좌</th>
                <th>시세</th>
                <th>사용가능수량</th>
                <th>원화계산</th>
                <th>타입</th>
            </tr>
            </thead>
            <tbody>
            <tr className="total-row">
                <td>합계</td>
                <td className="right-align">{Math.floor(totals.tradingAccount).toLocaleString()} USDT</td>
                <td className="right-align">{Math.floor(totals.fundingAccount).toLocaleString()} USDT</td>
                <td className="right-align">-</td>
                <td className="right-align">{Math.floor(totals.availableAmount).toLocaleString()} USDT</td>
                <td className="right-align">{Math.floor(totals.krwCalculation).toLocaleString()} KRW</td>
                <td className="right-align">-</td>
            </tr>
            {partnerData.map((partner) => {
                const tradingAccount = Number(partner.partnerData?.binance_data?.free) || 0;
                const fundingAccount = Number(partner.partnerData?.binance_data?.funding_free) || 0;
                const lastPrice = partner.partnerData?.binance_data?.last_price || 0;
                const availableAmount = Math.floor(tradingAccount) + Math.floor(fundingAccount) || 0;
                const krwCalculation = availableAmount * lastPrice || 0;
                return (
                    <tr key={partner.id} onClick={() => handleRowClick(partner)}>
                        <td>{partner.username}</td>
                        <td className="right-align">{Math.floor(tradingAccount).toLocaleString()} USDT</td>
                        <td className="right-align">{Math.floor(fundingAccount).toLocaleString()} USDT</td>
                        <td className="right-align">{formatKrw(lastPrice)} 원</td>
                        <td className="right-align">{Math.floor(availableAmount).toLocaleString()} USDT</td>
                        <td className="right-align">{Math.floor(krwCalculation).toLocaleString()} 원</td>
                        <td className="right-align">{partner.partner.trade_type}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
};

export default PartnerTable;
