import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import ParseJwt from "./ParseJwt";

const ProtectedRoute = ({ children, requiredRole = null }) => {
    const { loading, dispatch, logout } = useContext(UserContext);
    const [redirectPath, setRedirectPath] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        if (token) {
            const userData = ParseJwt(token);
            const currentTime = Math.floor(Date.now() / 1000);

            if (userData.exp < currentTime) {
                // 토큰이 만료된 경우
                logout();
                setRedirectPath('/login');  // 로그인 페이지로 리디렉트 설정
            } else if (requiredRole && userData.user_role !== requiredRole) {
                // 필요한 역할이 없는 경우, 홈으로 리디렉트 설정
                setRedirectPath('/');
            } else {
                // 로그인 상태 유지
                dispatch({ type: 'LOGIN', payload: userData });
            }
        } else {
            // 토큰이 없을 경우, 로그인 페이지로 리디렉트 설정
            setRedirectPath('/login');
            dispatch({ type: 'SET_LOADING', payload: false });
        }
        // eslint-disable-next-line
    }, [token]); // 의존성 배열을 최소화

    if (loading) {
        // 로딩 중일 때 로딩 표시 또는 빈 화면을 렌더링
        return <div>Loading...</div>;
    }

    if (redirectPath) {
        // 리디렉션 경로가 설정되어 있으면 해당 경로로 리디렉트
        return <Navigate to={redirectPath} />;
    }

    return children;
};

export default ProtectedRoute;
